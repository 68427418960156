<template>
    <div class="list-div">
        <el-card class="el-card-mainview">
            <el-row :gutter="30">
                <el-col :span="8">
                    <el-input placeholder="请输入学校名" v-model="queryInfo.Name" clearable @clear="quertSchoolList"> 
                        <el-button slot="append" icon="el-icon-search" @click="quertSchoolList"></el-button>
                    </el-input>
                </el-col>
                <el-col :span="4">
                    <el-button type="primary" @click="addDialogVisible = true">新建学校</el-button>
                </el-col>
            </el-row>
            <el-table :data="schoolList" @row-click="showEditDialog" v-loading="loading" height="74vh">
                <el-table-column label="#" type="index"></el-table-column>
                <el-table-column label="名称" prop="name"></el-table-column>
                <el-table-column label="省份" prop="province"></el-table-column>
                <el-table-column label="城市" prop="city"></el-table-column>
                <el-table-column label="地址" prop="address"></el-table-column>
                <el-table-column label="创建日期" prop="createTime"></el-table-column>
                <el-table-column label="操作" prop="option" width="300">
                    <template slot-scope="scope"> 
                        <el-tooltip effect="dark" content="删除" placement="top" :enterable="false">
                            <el-button type="danger" icon="el-icon-delete" @click.stop="deleteSchoolByID(scope.row.id)"></el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryInfo.Start"
                :page-sizes="spreadCount"
                :page-size="queryInfo.Count"
                layout="total, sizes, prev, pager, next, jumper"
                :total="schoolTotal">
            </el-pagination>
        </el-card>
        
        <AddSchoolDialog :visible.sync="addDialogVisible"
        v-on:ChangeAddSchool="quertSchoolList">
        </AddSchoolDialog>

        <ModifySchoolDialog :visible.sync="modifyDialogVisible" :schooldata="schoolData"
        v-on:ChangeModifySchool="quertSchoolList">
        </ModifySchoolDialog>
    </div>
</template>

<script>
import AddSchoolDialog from './Dialog/addschooldialog.vue';
import ModifySchoolDialog from './Dialog/modifyschooldialog.vue';
import { GetSchoolsQuery, DeleteSchoolByID } from '@/assets/js/api/schoolapi';
import { SpreadCount } from '@/assets/js/systemvariable.js';
export default {
    components:{
        AddSchoolDialog,
        ModifySchoolDialog,
    },
    data(){
        return{
            spreadCount:SpreadCount,
            schoolData:{},
            queryInfo:{
                Name:'',
                Start:1,
                Count:SpreadCount[0],
                state:0
            },
            modifyDialogVisible:false,
            addDialogVisible:false,
            schoolList:[],
            schoolTotal:0,
            loading:false,
        }
    },
    created(){
        this.GetSchoolList()
    },
    methods:{
        quertSchoolList(){
            this.queryInfo.Start = 1
            this.queryInfo.Count = this.spreadCount[0]
            this.GetSchoolList()
        },
        async GetSchoolList(){
            this.loading = true
            var ret = await GetSchoolsQuery(this.queryInfo)
            this.loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.schoolList = ret.data.schools
                this.schoolTotal = ret.data.total
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
        handleSizeChange(count){
            this.queryInfo.Count = count
            this.GetSchoolList()
        },
        handleCurrentChange(start){
            this.queryInfo.Start = start
            this.GetSchoolList()
        },
        showEditDialog(schoolInfo){
            this.schoolData = schoolInfo
            this.modifyDialogVisible = true
        },
        async deleteSchoolByID(id){
            var that = this;
            const confirmResult = await that.$confirm('此操作将永远删除该学校, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).
            catch(err=>err);
            if(confirmResult !== 'confirm'){
                return that.$message.info('已取消删除')
            }
            this.loading = true
            var ret = await DeleteSchoolByID(id)
            this.loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.GetSchoolList()
            }
            else
            {
                this.$message.error(ret.message);
            }
        }
    }
}
</script>