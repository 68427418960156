<template>
    <el-dialog title="编辑学校" :visible.sync="modifySchoolDialogVisible" width="30%" :close-on-click-modal="false" @close="closeDialog">
        <el-form  ref="schoolFormRef" :model="schoolForm" :rules="schoolFormRules" label-width="80px" v-loading="loading">
            <el-form-item label="名称" prop="Name">
                <el-input class="form_input_div" placeholder="请输入学校名称" autocomplete="off" v-model="schoolForm.Name"></el-input>
            </el-form-item>
            <el-form-item label="城市">
                <el-cascader class="cascader_div" :options="options" v-model="selectedOptions" @change="handleChange">
                </el-cascader>
            </el-form-item>
            <el-form-item label="地址" prop="Address">
                <el-input class="form_input_div" placeholder="请输入学校地址" autocomplete="off"  v-model="schoolForm.Address"></el-input>
            </el-form-item>
            <el-form-item label="创建日期" prop="CreateTime">
                <el-date-picker v-model="schoolForm.CreateTime" class="picker_div" type="date" placeholder="请选择选择日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
                </el-date-picker>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog" :disabled="loading">取消</el-button>
            <el-button type="primary" @click="modifySchool" :disabled="loading">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { provinceAndCityData, CodeToText,TextToCode } from 'element-china-area-data'
import { PutSchool } from '@/assets/js/api/schoolapi'
export default {
    props:{
        visible:{
            type: Boolean,
            default:false
        },
        schooldata:Object,
    },
    computed:{
        modifySchoolDialogVisible:{
            get: function() {
                return this.visible
            },
            set: function(val) {
                this.$emit('update:visible', val)
            }
        }
    },
    watch:{
        modifySchoolDialogVisible:function (newVisible) {
            if(newVisible){
                if(this.$refs.schoolFormRef){
                    this.$refs.schoolFormRef.resetFields()
                }
                this.schoolForm.ID = this.schooldata.id
                this.schoolForm.Name = this.schooldata.name
                this.schoolForm.Province = this.schooldata.province
                this.schoolForm.City = this.schooldata.city
                this.schoolForm.Address = this.schooldata.address
                this.schoolForm.CreateTime = this.schooldata.createTime
                this.selectedOptions = [TextToCode[this.schoolForm.Province].code.toString(),TextToCode[this.schoolForm.Province][this.schoolForm.City].code.toString()]
            }
        }
    },
    data(){
        return{
            schoolForm:{
                Name:'',
                Province:'山东省',
                City:'潍坊市',
                Address:'',
                CreateTime:new Date().toLocaleDateString().split('/').map(item=>{if (item<10){return '0'+ item}else {return item}}).join('-')
            },
            schoolFormRules:{
                Name: [
                    { required: true, message: '请输入名称', trigger: 'blur' }
                ],
                Address: [
                    { required: true, message: '请输入地址', trigger: 'blur' }
                ],
                CreateTime: [
                    { required: true, message: '请选择日期', trigger: 'change' }
                ]
            },
            loading:false,
            options:provinceAndCityData,
            selectedOptions:[],
        }
    },
    methods:{
        handleChange (value) {
            this.schoolForm.Province = CodeToText[value[0]]
            this.schoolForm.City = CodeToText[value[1]]
        },
        closeDialog(){
            this.modifySchoolDialogVisible = false
        },
        async modifySchool()
        {
            if(this.schoolForm.Name == this.schooldata.name &&
                this.schoolForm.Province == this.schooldata.province &&
                this.schoolForm.City == this.schooldata.city &&
                this.schoolForm.Address == this.schooldata.address &&
                this.schoolForm.CreateTime == this.schooldata.createTime){
                return this.$message.warning('数据没有修改，不需要提交');
            }
            this.$refs.schoolFormRef.validate(valid =>{
                this.validret = valid
            })
            if(!this.validret){
                return
            }   
            this.loading = true
            var ret = await PutSchool(this.schoolForm)
            this.loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.$message.success('编辑学校成功')
                this.$emit("ChangeModifySchool")
                this.closeDialog()
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
    }
}
</script>

<style lang="less" scoped>
    .cascader_div{
        width: 20vw;
        display:table
    }
    .form_input_div{
        width: 20vw;
        display: flex;
    }
    .picker_div{
        width: 20vw;
        display:flex
    }
</style>