import axios from 'axios';

//所有校区
export async function GetSchools(){
  var res = await axios.get('School/v1/Schools')
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    console.log(error);
    return null;
  });
  return res;
}

//新建学校
export async function PostSchool(school){
  var res = await axios.post('School/v1/School', school)
  .then(function(response){
      return response.data;
  })
  .catch(function (error){
      console.log(error);
      return null;
  });
  return res;
}

//编辑学校
export async function PutSchool(school){
  var res = await axios.put('School/v1/School', school)
  .then(function(response){
      return response.data;
  })
  .catch(function (error){
      console.log(error);
      return null;
  });
  return res;
}

//查询学校
export async function GetSchoolsQuery(query){
  var res = await axios.get('School/v1/SchoolsQuery', {params: query})
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    console.log(error);
    return null;
  });
  return res;
}

//根据ID删除学校
export async function DeleteSchoolByID(id){
  var res = await axios.delete('School/v1/SchoolByID', {params: {id : id}})
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    console.log(error);
    return null;
  });
  return res;
}